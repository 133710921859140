import React from "react";
import "./index.css";

function Progress() {
  return (
    <div className="wrapper">
      <div className="loader" />
    </div>
  );
}

export default Progress;
